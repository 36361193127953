<template>
  <div
    v-shortkey="['esc']"
    class="modals-video"
    @contextmenu.self.prevent
    @click.stop="showBannerBased = false"
    @shortkey="showBannerBased = false"
  >
    <div
      class="modals-video__content"
      @click.stop
      @contextmenu.prevent
    >
      <div class="modals-video__close">
        <i
          class="icon-delete"
          @click="showBannerBased = false"
        ></i>
      </div>
      <div class="video-block__iframe video-block__iframe_video">
        <iframe
          src="https://player.vimeo.com/video/359960658?autoplay=1"
          allow="autoplay; encrypted-media"
          allowfullscreen="allowfullscreen"
        >
        </iframe>
      </div>
      <!--      <video-->
      <!--        id="player"-->
      <!--        class="modals-video-js video-js"-->
      <!--        controls="true"-->
      <!--        preload="auto"-->
      <!--        data-setup='{}'-->
      <!--      >-->
      <!--      </video>-->
    </div>
  </div>
</template>

<script>
// import videojs from 'video.js'
export default {
  name: 'ModalsBannerBased',
  data() {
    return {
      playerVideo: '',
    };
  },
  computed: {
    showBannerBased: {
      get() {
        return this.$store.getters.showBannerBased;
      },
      set(data) {
        this.$store.commit('setShowBannerBased', data);
      },
    },
  },
  // beforeMount: function () {
  //   this.$nextTick(() => {
  //     setTimeout(() => {
  //       let options = {
  //         autoplay: true,
  //         controls: true,
  //         fluid: true,
  //         aspectRatio: '16:9',
  //         poster: '',
  //         // type: 'video/mp4
  //         sources: ([
  //           { src: 'https://cdn-2.impactfactors.net/uploads/2/lifestylechristianity2/190828/qYG3qsjnpY2nnX5HNxBBHaeyO67y4QCl/video/mp4/E8fUro8G.mp4',
  //             type: 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"' }
  //         ])
  //       }
  //       console.log(options)
  //       this.playerVideo = videojs('player', options, function onPlayerReady () {
  //         this.play()
  //         videojs.log('Your player is ready!')
  //         this.on('ended', function () {
  //           videojs.log('Awww...over so soon?!')
  //         })
  //       })
  //     }, 300)
  //   })
  // },
  // beforeDestroy: function () {
  //   this.playerVideo.dispose()
  // }
};
</script>

<style lang="scss">
  @import '../../assets/css/video-js.css';
  @import '../../assets/sass/utils/variables';

  .modals-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity .5s ease-in;
    background: rgba(0, 0, 0, .8);

    &__close {
      display: flex;
      justify-content: flex-end;
      position: relative;
      top: -20px;
      left: 40px;
      transition: color .2s ease-in;
      cursor: pointer;

      &:hover {
        color: $color-white;
      }
    }

    &__bg {
      background: rgba(0, 0, 0, 0);
      animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    }

    &__content {
      min-width: 70vw;
      width: 70vw;

      video {
        width: 100%;
      }
    }
  }

  @keyframes scaleUp {
    0% {
      transform: translateY(1000px);
      opacity:0;
    }
    100% {
      transform: translateY(0);
      opacity:1;
    }
  }

  @keyframes scaleDown {
    0% {
      transform: translateY(0);
      opacity:1;
    }
    100% {
      transform: translateY(1000px);
      opacity:0;
    }
  }
</style>

<style lang="scss" scoped>
  .video-block__iframe_video {
    display: block;
    min-width: 70vw;
    max-height: 100%;
    overflow: hidden;
    position: relative;
    padding-bottom: 56.25%;
    line-height: 0;
    height: 0;
    width: 100%;
    cursor: pointer;

    iframe, video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%!important;
      height: 100%!important;
    }

  }
</style>
