<template>
  <transition
    name="fade-block-wait"
    appear
    mode="out-in"
  >
    <div
      v-shortkey="['esc']"
      class="modals-new-project"
      @contextmenu.self.prevent
      @click="showModalNewProject = false"
      @shortkey="showModalNewProject = false"
    >
      <div
        class="modals-new-project__content"
        @click.stop
        @contextmenu.prevent
      >
        <div
          v-if="!shadowHide"
          :ref="['ps-shadow-top-' + perfectScrollBarRef]"
          class="ps-shadow__top"
          :class="[{'ps-shadow__top_hide' :!shadowTop}]"
        >
        </div>
        <div
          v-if="!shadowHide"
          :ref="['ps-shadow-bottom-' + perfectScrollBarRef]"
          class="ps-shadow__bottom"
          :class="[{'ps-shadow__bottom_hide' :!shadowBottom}]"
        >
        </div>
        <div
          class="modals-new-project__close"
          @click="showModalNewProject = false"
        >
          <i class="icon-close-modal"></i>
        </div>
        <div class="modals-new-project__header">
          <!--        <div class="modals-new-project__main-title">-->
          <!--          New Project-->
          <!--        </div>-->
        </div>
        <VuePerfectScrollbar
          :ref="perfectScrollBarRef"
          class="modals-new-project__body ps-shadow"
          @ps-y-reach-start="scrollYStart()"
          @ps-y-reach-end="scrollYEnd()"
          @ps-scroll-y="scrollY()"
        >
          <div class="modals-new-project__title">
            Please select homepage layout
          </div>
          <div class="template">
            <div class="template-list">
              <div
                v-for="(item, index) in projectTemplate"
                :key="item.id"
                class="template-item"
              >
                <div class="template-item__header">
                  <div class="template-item__title">
                    {{ item.title }}
                  </div>
                  <button
                    class="template-item__btn template-item__btn_icon"
                    @click.prevent="quickIntro(index)"
                  >
                    <i class="icon-camera"></i>
                    <span>Quick Intro</span>
                  </button>
                </div>
                <div class="template-item__body">
                  <a
                    :href="item.link"
                    target="_blank"
                    class="template-item__img"
                  >
                    <img
                      :src="require('../../assets/images/' + item.image + '.png')"
                      alt=""
                    >
                    <div
                      v-if="index === 1"
                      class="template-item__recommend"
                    >
                      Recommended
                    </div>
                  </a>
                </div>
                <div class="template-item__footer">
                  <a
                    :href="item.link"
                    target="_blank"
                    class="template-item__btn template-item__btn_small"
                  >
                    Preview this layout
                  </a>
                  <!--                <button class="template-item__btn template-item__btn_small">-->
                  <!--                  Use This Layout-->
                  <!--                </button>-->
                </div>
              </div>
            </div>
          </div>
        </VuePerfectScrollbar>
      </div>
      <transition
        name="fade-modal"
        appear
      >
        <modals-banner-based
          v-if="showBannerBased"
        >
        </modals-banner-based>
      </transition>
      <transition
        name="fade-modal"
        appear
      >
        <modals-widget-based
          v-if="showWidgetBased"
        >
        </modals-widget-based>
      </transition>
      <transition
        name="fade-modal"
        appear
      >
        <modals-one-pager
          v-if="showOnePager"
        >
        </modals-one-pager>
      </transition>
    </div>
  </transition>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import modalsBannerBased from '@/components/modals/modalsBannerBased';
import modalsWidgetBased from '@/components/modals/modalsWidgetBased';
import modalsOnePager from '@/components/modals/modalsOnePager';

export default {
  name: 'ModalsNewProject',
  components: {
    modalsBannerBased,
    modalsWidgetBased,
    modalsOnePager,
    VuePerfectScrollbar,
  },
  data() {
    return {
      perfectScrollBarRef: Math.floor(new Date().valueOf() * Math.random()),
      shadowBottom: false,
      shadowTop: false,
      shadowHide: false,
      projectTemplate: [
        {
          id: 0,
          title: 'Banner-based',
          image: 'template1',
          link: 'https://gracechurch.impactfactors.net/widget/website-v2/banner-based',
        },
        {
          id: 1,
          title: 'Widgets-based',
          image: 'template2',
          link: 'https://gracechurch.impactfactors.net/widget/website-v2/widget-based/',
        },
        {
          id: 3,
          title: 'One-pager',
          image: 'template3',
          link: 'https://gracechurch.impactfactors.net/widget/website-v2/one-pager',
        },
      ],
    };
  },
  computed: {
    showBannerBased: {
      get() {
        return this.$store.getters.showBannerBased;
      },
      set(data) {
        this.$store.commit('setShowBannerBased', data);
      },
    },
    showOnePager: {
      get() {
        return this.$store.getters.showOnePager;
      },
      set(data) {
        this.$store.commit('setShowOnePager', data);
      },
    },
    showWidgetBased: {
      get() {
        return this.$store.getters.showWidgetBased;
      },
      set(data) {
        this.$store.commit('setShowWidgetBased', data);
      },
    },
    showModalNewProject: {
      get() {
        return this.$store.getters.showModalNewProject;
      },
      set(data) {
        this.$store.commit('setShowModalNewProject', data);
      },
    },
  },
  beforeMount() {
    document.addEventListener('keyup', this.keyup);
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.keyup);
  },
  methods: {
    scrollYStart() {
      if (this.$refs[this.perfectScrollBarRef].$el.classList.contains('ps--active-y') && !this.shadowHide) {
        this.shadowTop = false;
        this.shadowBottom = true;
      }
    },
    scrollYEnd() {
      if (this.$refs[this.perfectScrollBarRef].$el.classList.contains('ps--active-y') && !this.shadowHide) {
        this.shadowBottom = false;
      }
    },
    scrollY() {
      if (this.$refs[this.perfectScrollBarRef].$el.classList.contains('ps--active-y') && !this.shadowHide) {
        this.shadowTop = true;
        this.shadowBottom = true;
      }
    },
    quickIntro(index) {
      if (index === 0) {
        this.showBannerBased = true;
      } else if (index === 1) {
        this.showWidgetBased = true;
      } else if (index === 2) {
        this.showOnePager = true;
      }
    },
    keyup(e) {
      console.log(e);
      const escKey = 27;
      if (e.keyCode === escKey) {
        e.preventDefault();
        e.stopPropagation();
        this.showOnePager = false;
        // return false
      }
    },
  },
};
</script>

<style lang="scss">
  @import '../../assets/sass/utils/variables';

  .modals-new-project {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .8);

    &__content {
      max-width: 1018px;
      width: 100%;
      background: $color-white;
      border-radius: 3px;
      position: relative;
      padding: 0 15px 40px 15px;
    }

    &__close {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 12px;
      color: $color-gray-chateau;
      transition: transform .15s ease-in;
      cursor: pointer;
      z-index: 50;

      &:hover {
        transform: rotate(90deg);
      }
    }

    &__header {
      /*padding-top: 12px;
      padding-bottom: 9px;
      margin: 0 -15px;
      border-bottom: 1px solid $color-mercury;*/
      height: 41px;
    }

    &__body {
      padding-top: 32px;
    }

    &__main-title {
      display: block;
      font-family: $font-third, sans-serif;
      font-size: 16px;
      padding: 0 15px;
      color: $color-gray-chateau;
    }

    &__title {
      font-family: $font-third, sans-serif;
      font-size: 21px;
      line-height: 32px;
      color: $color-black;
      text-align: center;
      margin-bottom: 24px;
    }

    &-list {
      display: flex;
      justify-content: center;
    }
  }

  @keyframes scaleUp {
    0% {
      transform: translateY(1000px);
      opacity:0;
    }
    100% {
      transform: translateY(0);
      opacity:1;
    }
  }
  @keyframes scaleDown {
    0% {
      transform: translateY(0);
      opacity:1;
    }
    100% {
      transform: translateY(1000px);
      opacity:0;
    }
  }

  .template {
    font-family: $font-third, sans-serif;

    &-list {
      display: flex;
      justify-content: center;
    }

    &-item {
      margin: 0 20px;

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
      }

      &__body {
        margin-bottom: 22px;
      }

      &__footer {
        display: flex;
        align-items: center;
        justify-content: center;

        .template-item__btn + .template-item__btn {
          margin-left: 18px;
        }
      }

      &__img {
        position: relative;
        line-height: 0;
        cursor: pointer;
        transition: all .25s ease-in;
        border-radius: 5px;
        border: none;
        display: block;

        &:hover, &:focus {
          transform: translateY(-8px) scale(1.05);
          box-shadow: 5px 16px 32px rgba(48, 49, 51, .1);
        }

      }

      &__recommend {
        position: absolute;
        left: 52%;
        top: 142px;
        transform: translateX(-50%);
        color: $color-melrose;
        font-size: 17px;
        line-height: 24px;
        font-family: $font-third-medium, sans-serif;
        font-style: italic;
        z-index: 10;
      }

      &__title {
        font-size: 18px;
      }

      &__btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $color-turquoise;
        width: auto;
        height: 32px;
        border: 1px solid $color-turquoise;
        border-radius: 3px;
        cursor: pointer;
        font-size: 14px;
        transition: all .2s ease-in;
        font-family: $font-third, sans-serif;
        text-decoration: none;
        text-transform: uppercase;
        line-height: normal;

        &_small {
          height: 24px;
          padding: 0 18px;
          width: auto;
          padding-top: 3px;
        }

        span {
          padding-top: 2px;
        }

        i {
          font-size: 24px;
          margin-right: 8px;
          position: relative;
          top: 1px;

          &:before {
            margin: 0;
          }
        }

        &:hover {
          color: $color-white;
          background: $color-turquoise;
        }

        &_icon {
          width: 120px;
          text-transform: none;
          border: none;

          &:hover {
            background: $color-white;
            color: $color-melrose;
          }
        }
      }
    }
  }

  @media (max-width: 1023px) {
    .modals-new-project {
      &__content {
        max-width: 100%;
        width: 100%;
        margin: 15px;
      }
    }

    .template {
      &-item {
        width: calc(33.33% - 20px);

        &__title {
          margin-bottom: 10px;
        }

        &__header {
          flex-direction: column;
        }

        &__footer {
          flex-direction: column;

          .template-item__btn + .template-item__btn {
            margin-left: 0;
            margin-top: 15px;
          }
        }

      }
    }

  }

  @media (max-width: 767px) {
    .modals-new-project {

    }
    .template {
      &-item {

        &__recommend {
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          font-size: 14px;
        }

        &__title {
          font-size: 16px;
        }

        &__btn {
          font-size: 12px;

          &_small {
            padding: 0 9px;
          }

          i {
            font-size: 20px;
          }
        }
      }
    }
  }

  @media (max-width: 650px) {
    .modals-new-project {
      &__title {
        line-height: 24px;
      }
    }

    .modals-new-project__body {
      max-height: 450px;
      overflow: auto;
    }

    .modals-new-project__content {
      padding: 0;
    }

    .modals-new-project__header {
      height: 0;
    }

    .template {

      &-list {
        flex-direction: column;
      }

      &-item {
        width: 100%;
        margin: auto;
        margin-bottom: 50px;

        &__btn {
          height: 28px;

          &_small {
            height: 24px;
          }
        }

        &__body {
          margin-bottom: 15px;
        }

        &__img {
          width: 220px;
          margin: auto;
        }
      }
    }
  }
  @media (max-width: 480px) {
    .modals-new-project {
      &__title {
        font-size: 19px;
      }
    }
  }
  @media (max-width: 370px) {
    .modals-new-project {
      &__title {
        max-width: 250px;
        margin: auto;
      }
    }
  }

  .modals-new-project__body {
    .ps__scrollbar-y-rail {
      opacity:  1 !important;
      right: 5px!important;
      border-radius: 2px;
      z-index: 3;
      width: 3px!important;

      .ps__scrollbar-y {
        width: 3px!important;
        left: 0!important;
        background: $color-chamrock!important;
      }
    }
  }
</style>

<style lang="scss" scoped>
  @import '../../assets/sass/utils/variables';
  .ps-shadow{
    &__top, &__bottom{
      position: absolute;
      left: 0;
      width: 100%;
      height: 20px;
      z-index: 2;
      opacity: 1;
      transition: opacity 100ms ease-out;

      &_hide{
        opacity: 0;
        z-index: -1!important;
      }
    }
    &__top{
      top: 0;
      background-image: linear-gradient(to top, rgba($color-black, 0) 0%, rgba($color-black, .2) 100%);
    }
    &__bottom{
      bottom: 0;
      background-image: linear-gradient(to top, rgba($color-black, .2) 0%, rgba($color-black, 0) 100%);
    }
  }
</style>
